<template>
  <v-row>
    <v-col v-if="keyInfo.subjOrg" cols="6">
      <div class="text-disabled">Назва компанії</div>
      <div>{{ keyInfo.subjOrg }}</div>
    </v-col>
    <v-col v-if="keyInfo.subjEDRPOUCode" cols="6">
      <div class="text-disabled">ЄДРПОУ</div>
      <div>{{ keyInfo.subjEDRPOUCode }}</div>
    </v-col>

    <v-col v-if="keyInfo.subjFullName" cols="6">
      <div class="text-disabled">ПІБ</div>
      <div>{{ keyInfo.subjFullName }}</div>
    </v-col>
    <v-col v-if="keyInfo.subjDRFOCode" cols="6">
      <div class="text-disabled">ІПН</div>
      <div>{{ keyInfo.subjDRFOCode }}</div>
    </v-col>

    <v-col cols="6">
      <div class="text-disabled">Місто</div>
      <div>{{ keyInfo.subjLocality }}</div>
    </v-col>
    <v-col cols="6">
      <div class="text-disabled">Орган що видав</div>
      <div>{{ keyInfo.issuerCN }}</div>
    </v-col>

    <v-col cols="12">
      <div class="text-disabled">Номер сертифікату</div>
      <div>{{ keyInfo.serial }}</div>
    </v-col>

    <v-col cols="6">
      <div class="text-disabled">Дата початку</div>
      <div>
        {{ new CustomDate(keyInfo.privKeyBeginTime).toString({ time: true }) }}
      </div>
    </v-col>
    <v-col cols="6">
      <div class="text-disabled">Дата закінчення</div>
      <div>
        {{ new CustomDate(keyInfo.privKeyEndTime).toString({ time: true }) }}
      </div>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { CustomDate } from 'best-modules/utils'
export default {
  name: 'KeyInfo',
  props: {
    keyInfo: {
      type: Object,
    },
  },
  data() {
    return {
      CustomDate,
    }
  },
}
</script>

<style scoped lang="scss"></style>
