import { Component, Ref, ref } from 'vue'
import components from './components/index'
import { generateId } from '@/utils/helpers.js'
import { cloneDeep } from 'lodash'
import { Empty } from '@/utils/types/helpers'

interface DialogComponent {
  component: Component
  title: (_dialog: Dialog) => string
  subtitle?: (_dialog: Dialog) => string
  width?: string | number
}

type DialogComponentsNames =
  | 'Document'
  | 'Order'
  | 'UserPhoto'
  | 'TemplateGroup'
  | 'User'
  | 'Absence'
  | 'RelatedOrder'
  | 'ChangeUser'
  | 'TemplateRoutingDocument'
  | 'Department'
  | 'ChangeDepartment'
  | 'FamiliarizationReport'
  | 'SelectEntities'
  | 'SimpleDialog'
  | 'Textarea'
  | 'Table'
  | 'DocumentAgreementUsers'
  | 'DocumentAgreementHistory'
  | 'FamiliarizationUsers'
  | 'Comments'
  | 'OrderDeactivate'
  | 'Contractor'
  | 'ContractorContact'
  | 'Correspondence'
  | 'CorrespondenceDeliver'
  | 'RelatedCorrespondence'
  | 'RelatedTask'
  | 'CopyTask'
  | 'Notes'
  | 'ClientAccess'
  | 'SignDocument'
  | 'SignDiia'
  | 'DocumentSendToSign'

type DialogComponents = Record<DialogComponentsNames, DialogComponent>

interface Dialog {
  isOpen?: boolean
  loading: boolean
  name: keyof DialogComponents
  action?: string
  params?: {
    cb?: (..._args) => any
    title?: string
    subtitle?: string
    submitLabel?: string
    cancelLabel?: string
    hideActionButtons?: boolean
  }
  dialogData?: Record<string, any>
  id?: string
  ref?: {
    submit: () => any
    validationHandler?: () => any
    v$: any
    disabled?: boolean
  }
  close: () => void
  submit: (_res) => Promise<void>
}

const getDialogInstance = () => {
  const dialogs: Ref<Dialog[]> = ref([])

  const defaultDialog: Dialog = {
    isOpen: false,
    loading: true,
    name: null,
    action: null,
    params: {},
    dialogData: {},
    close() {
      closeDialog(this)
    },
    async submit(res) {
      try {
        typeof this.params.cb === 'function' && this.params.cb(res?.data || res)
        this.close()
      } catch (e) {
        throw new Error(
          `
            dialog: ${this.name}.vue has unexpected error on submit action
            Error: ${e}
          `
        )
      }
    },
  }

  function setDialogRef(dialog: Dialog) {
    return componentRef => {
      dialog.ref = componentRef
    }
  }

  function openDialog(dialogData: Empty<Dialog> & { name: string }) {
    if (Object.keys(components).includes(dialogData.name)) {
      const id = generateId()
      const dialog = {
        ...defaultDialog,
        ...cloneDeep(dialogData),
        isOpen: true,
        ref: null,
        id,
      }
      dialogs.value.push(dialog)
    } else {
      throw new Error(`Invalid dialog name - ${dialogData.name}`)
    }
  }

  function closeDialog(dialog: Dialog) {
    dialog.isOpen = false
    setTimeout(() => {
      dialogs.value = dialogs.value.filter(d => d.id !== dialog.id)
    }, 500)
  }

  function closeDialogs() {
    dialogs.value.splice(0)
  }

  return { openDialog, closeDialog, dialogs, setDialogRef, closeDialogs }
}

const dialogInstance = getDialogInstance()

const useDialog = () => dialogInstance

const openDialog = dialogInstance.openDialog
const closeDialog = dialogInstance.closeDialogs

export { useDialog, openDialog, closeDialog, DialogComponents, Dialog }
