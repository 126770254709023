import { DialogComponents } from '../index'
import Document from './document/Document.vue'
import Order from './Order.vue'
import UserPhoto from './user/photo/Index.vue'
import TemplateGroup from './templateGroup/Index.vue'
import User from './User.vue'
import Absence from './Absence.vue'
import RelatedOrder from './RelatedOrder.vue'
import ChangeUser from './ChangeUser.vue'
import TemplateRoutingDocument from './TemplateRoutingDocument.vue'
import Department from './Department.vue'
import ChangeDepartment from './ChangeDepartment.vue'
import FamiliarizationReport from './FamiliarizationReport.vue'
import SelectEntities from './SelectEntities.vue'
import SimpleDialog from './SimpleDialog.vue'
import Textarea from './Textarea.vue'
import Table from './Table.vue'
import DocumentAgreementUsers from './document/DocumentAgreementUsers.vue'
import DocumentAgreementHistory from './document/DocumentAgreementHistory.vue'
import FamiliarizationUsers from './FamiliarizationUsers.vue'
import Comments from './comments/Comments.vue'
import OrderDeactivate from './OrderDeactivate.vue'
import Contractor from './contractor/Contractor.vue'
import ContractorContact from './contractor/Contact.vue'
import Correspondence from './correspondence/Correspondence.vue'
import CorrespondenceDeliver from './correspondence/Deliver.vue'
import RelatedCorrespondence from './correspondence/Related.vue'
import RelatedTask from './task/RelatedTask.vue'
import Notes from './notes/Notes.vue'
import ClientAccess from './client-access/ClientAccess.vue'
import SignDocument from './sign-document/SignDocument.vue'
import SignDiia from './sign-document/sign-cloud/SignDiia.vue'
import CopyTask from './task/CopyTask.vue'
import DocumentSendToSign from './document/DocumentSendToSign.vue'

const invalidAction = () => {
  throw new Error('Invalid dialog action')
}
const getDefaultTitle = (action: string): string => {
  switch (action) {
    case 'create':
      return 'Створити'
    case 'update':
      return 'Редагувати'
    case 'delete':
      return 'Видалити'
    default:
      return invalidAction()
  }
}

const components: DialogComponents = {
  SimpleDialog: {
    component: SimpleDialog,
    title() {
      return ''
    },
  },
  Document: {
    component: Document,
    width: '750px',
    title({ action }) {
      return `${getDefaultTitle(action)} документ`
    },
  },
  DocumentSendToSign: {
    component: DocumentSendToSign,
    title() {
      return 'Відправка документа на підписання'
    },
  },
  Order: {
    component: Order,
    title({ action }) {
      return `${getDefaultTitle(action)} наказ`
    },
  },
  UserPhoto: {
    component: UserPhoto,
    title() {
      return 'Змінити фото'
    },
  },
  TemplateGroup: {
    component: TemplateGroup,
    width: '850px',
    title() {
      return 'Налаштування маршрута'
    },
  },
  User: {
    component: User,
    width: '55rem',
    title() {
      return 'Створити користувача'
    },
  },
  Absence: {
    component: Absence,
    title() {
      return 'Створити відпустку'
    },
  },
  RelatedOrder: {
    component: RelatedOrder,
    title() {
      return "Додати пов'язаний наказ"
    },
  },
  ChangeUser: {
    component: ChangeUser,
    title() {
      return 'Змінити користувача'
    },
  },
  TemplateRoutingDocument: {
    component: TemplateRoutingDocument,
    title() {
      return 'Створити шаблон'
    },
  },
  Department: {
    component: Department,
    title() {
      return 'Створити департамент'
    },
  },
  ChangeDepartment: {
    component: ChangeDepartment,
    title() {
      return 'Змінити депаратамент'
    },
  },
  FamiliarizationReport: {
    component: FamiliarizationReport,
    title() {
      return 'Сформувати звіт'
    },
  },
  SelectEntities: {
    component: SelectEntities,
    title() {
      return 'Додати користувачів'
    },
  },
  Textarea: {
    component: Textarea,
    title({ dialogData }) {
      const { dialogTitle = 'Введіть текст' } = dialogData
      return dialogTitle
    },
  },
  Table: {
    component: Table,
    title({ dialogData }) {
      const { dialogTitle = 'Список' } = dialogData
      return dialogTitle
    },
  },
  DocumentAgreementUsers: {
    component: DocumentAgreementUsers,
    title() {
      return 'Учасники погодження'
    },
    subtitle({ dialogData }) {
      return String(dialogData.name)
    },
  },
  DocumentAgreementHistory: {
    component: DocumentAgreementHistory,
    title() {
      return 'Історія погодження'
    },
    subtitle({ dialogData }) {
      return String(dialogData.name)
    },
  },
  FamiliarizationUsers: {
    component: FamiliarizationUsers,
    title() {
      return 'Учасники ознайомлення'
    },
    subtitle({ dialogData }) {
      return String(dialogData.name)
    },
  },
  Comments: {
    component: Comments,
    title() {
      return 'Коментарі'
    },
  },
  OrderDeactivate: {
    component: OrderDeactivate,
    title() {
      return 'Деактивація наказу'
    },
  },
  Contractor: {
    component: Contractor,
    title() {
      return 'Створити контрагент'
    },
  },
  ContractorContact: {
    component: ContractorContact,
    title({ action, dialogData }) {
      return `${getDefaultTitle(action)} ${dialogData.title}`
    },
  },
  Correspondence: {
    component: Correspondence,
    title() {
      return 'Створити кореспонденцію'
    },
  },
  CorrespondenceDeliver: {
    component: CorrespondenceDeliver,
    title() {
      return 'Доставка кореспонденції'
    },
  },
  RelatedCorrespondence: {
    component: RelatedCorrespondence,
    title() {
      return "Пов'язана кореспонденція"
    },
  },
  RelatedTask: {
    component: RelatedTask,
    title() {
      return "Пов'язані задачі"
    },
  },
  CopyTask: {
    component: CopyTask,
    title() {
      return 'Копіювання задачі'
    },
  },
  Notes: {
    component: Notes,
    title() {
      return 'Примітки'
    },
  },
  ClientAccess: {
    component: ClientAccess,
    title() {
      return 'Доступ до документа'
    },
  },
  SignDocument: {
    component: SignDocument,
    title() {
      return 'Підпис документа'
    },
  },
  SignDiia: {
    component: SignDiia,
    title() {
      return 'Дія. Накладання підпису'
    },
  },
}

export default components
