import { axios } from '@/plugins/index.js'
import { computed, ref, inject } from 'vue'
import {
  urlDocumentAgreementUpdate,
  urlDocumentSignatureUpdate,
} from '@/utils/urls.js'
import { navigateToItem } from '@/utils/helpers.js'
import { openDialog } from '@/dialog/index.ts'
import { cloneDeep } from 'lodash'
import { documentActionKey } from '@/pages/documents/injectionKeys'

export function useDocumentSingle(document, state) {
  const documentAction = inject(documentActionKey)
  const newFile = ref(null)

  const disabled = computed(() => {
    return !state.isAuthor || [3, 4].includes(document.statusId)
  })

  const updateDocument = () => {
    const getUrl = () => {
      switch (documentAction.value) {
        case 'signature':
          return urlDocumentSignatureUpdate(document.id)
        case 'agreement':
          return urlDocumentAgreementUpdate(document.id)
      }
    }
    const requestObj = {
      ...document.$object,
      document: newFile.value || document.document,
    }

    return axios.post(getUrl(), requestObj).then(res => {
      newFile.value = null
      document.$set(res.data)
    })
  }
  const openEditDocumentDialog = () => {
    openDialog({
      name: 'Document',
      action: 'update',
      dialogData: {
        document: cloneDeep(document.$object),
        documentAction: documentAction.value,
        disabled: disabled.value,
      },
      params: {
        cb: updatedDocument => {
          document.$set(updatedDocument)
        },
        submitLabel: 'Зберегти',
      },
    })
  }

  /** Opening dialogs */
  const showDocumentHistory = () => {
    openDialog({
      name: 'DocumentAgreementHistory',
      dialogData: document.$object,
      params: {
        hideActionButtons: true,
      },
    })
  }
  const showAgreedHistory = () => {
    openDialog({
      name: 'DocumentAgreementUsers',
      dialogData: document.$object,
      params: {
        hideActionButtons: true,
      },
    })
  }

  const relatedDocuments = computed(() => {
    const agreements =
      document.related_document_agreements?.map(d => {
        return { ...d, routeName: 'document-agreement-single' }
      }) || []
    const signature =
      document.related_document_signatures?.map(d => {
        return { ...d, routeName: 'document-signature-single' }
      }) || []

    return [...agreements, ...signature]
  })

  return {
    newFile,
    disabled,
    updateDocument,
    openEditDocumentDialog,
    navigateToItem,
    showDocumentHistory,
    showAgreedHistory,
    relatedDocuments,
  }
}
