import {
  axios,
  required,
  useBreadcrumb,
  useRoute,
  useStore,
  useVuelidate,
  minLength,
} from '@/plugins/index.js'
import { computed, ref } from 'vue'
import { urlCreateTask } from '@/utils/urls.js'
import { navigateToItem } from '@/utils/helpers.js'
import { setSnackbar } from 'best-modules/plugins'
import { fillDirectory } from '@/directory'
import { TaskCredentials } from '@/utils/types/task'
import { cachedObject } from 'best-modules/utils'

export function useCreateTask(taskData) {
  const route = useRoute()
  const store = useStore()
  const breadcrumb = useBreadcrumb()
  const submitLoading = ref(false)
  const typeId = +route.params.typeId || 1
  const entityId = +route.query.entityId
  const userId = computed(() => store.state.user.userData?.id)

  fillDirectory(['userList', 'contractorList', 'taskTypes'])

  const taskType = {
    execution: typeId === 1,
    errand: [3, 4].includes(typeId),
    familiarization: false,
  }

  if (taskData) {
    breadcrumb.set([
      {
        title: taskData.name,
        to: {
          name: [3, 4].includes(+route?.params?.entityTypeId)
            ? 'single-order'
            : 'correspondence-single',
          params: { id: taskData.id },
        },
        index: 1,
      },
      {
        title: taskType.errand ? 'Доручення' : 'Ознайомлення',
        index: 2,
      },
    ])
  }

  const getDefaultTopic = () => {
    if (!taskData || taskType.execution) return null
    const topicTitle = taskType.errand
      ? 'Доручення'
      : 'Ознайомлення з документом'
    const docName = taskData ? `: "${taskData.name}"` : ''
    return `${topicTitle}${docName}`
  }
  const getDefaultDescription = () => {
    return taskType.familiarization ? 'Прошу ознайомитись з документом' : null
  }
  const getDefaultAttachment = () => {
    return taskData ? taskData.document : []
  }
  const getDefaultAuthorId = () => {
    return taskData?.issuedUserId ? taskData.issuedUserId : userId.value
  }
  const getDefaultDeadline = () => {
    return taskData ? taskData.familiarizationTerm || null : null
  }
  const getDefaultContractor = () => {
    return taskData ? taskData.contractorId : null
  }

  const task = cachedObject<TaskCredentials>({
    authorId: getDefaultAuthorId(),
    typeId: typeId,
    topic: getDefaultTopic(),
    description: getDefaultDescription(),
    isHighImportance: false,
    executorId: null,
    accessEntities: [],
    deadline: getDefaultDeadline(),
    attachment: getDefaultAttachment(),
    contractorId: getDefaultContractor(),
    entityId: entityId,
    entityTypeId: +route.params.entityTypeId || null,
  })

  const rules = computed(() => {
    return {
      authorId: !taskType.execution ? { required } : {},
      topic: taskType.execution ? { required } : {},
      description: taskType.errand ? {} : { required },
      executorId: !taskType.familiarization ? { required } : {},
      accessEntities: taskType.familiarization
        ? { required, minLength: minLength(0) }
        : {},
      deadline: { required },
      attachment: !taskType.execution ? { required } : {},
    }
  })
  const v$ = useVuelidate(rules, task)

  const submit = () => {
    v$.value.$validate()
    console.log(v$.value.$error)
    if (!v$.value.$error) {
      submitLoading.value = true
      return axios.post(urlCreateTask(), task).then(res => {
        submitLoading.value = false
        const taskTypeName = taskType.errand ? 'Доручення' : 'Завдання'
        setSnackbar({
          text: `${taskTypeName} успішно створено`,
        })
        navigateToItem('single-task', res.data.id)
      })
    }
  }
  const cancel = () => {
    navigateToItem('tasks')
  }

  return {
    submitLoading,
    taskType,
    typeId,
    task,
    v$,
    disabled: false,
    submit,
    cancel,
  }
}
