<template>
  <v-card>
    <v-card-text class="pr-0">
      <div id="notification-container" class="notification__container">
        <div
          v-for="item in notifications"
          :id="`notification-${item.id}`"
          :key="item.id"
          class="notification"
          @click="
            $router.push({
              name: notificationTypesMap[item.section].route,
              params: { id: item.rowId },
            })
          ">
          <div
            :class="{
              isRead: item.isRead,
            }"
            class="notification__status"></div>
          <div>
            <div class="mb-1">
              <span class="d-block font-weight-bold mb-1">{{
                notificationTypesMap[item.section].title
              }}</span>
              <span v-html="curMessage(item.message)"></span>
            </div>
            <div>
              <span style="font-size: 11px">{{
                formatDate(item.created_at, 'response')
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!notifications.length" class="text-disabled text-center">
        Сповіщення відсутні
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { useStore } from 'vuex'
import { computed, onMounted, onUnmounted } from 'vue'
import { formatDate } from '@/plugins/axios/helpers.js'

export default {
  name: 'NotificationList',
  methods: { formatDate },
  setup() {
    const store = useStore()
    const notifications = computed(
      () => store.state.notifications.notificationList
    )

    const notificationTypesMap = {
      document_agreement: {
        route: 'document-agreement-single',
        title: 'Документ на погодження',
      },
      document_signature: {
        route: 'document-signature-single',
        title: 'Документ на підписання',
      },
      order: {
        route: 'single-order',
        title: 'Наказ',
      },
      task: {
        route: 'single-task',
        title: 'Завдання',
      },
    }

    const curMessage = computed(() => message => {
      return message
    })

    onMounted(() => {
      // JavaScript (или в вашем компоненте Vue)
      const notificationsContainer = document.getElementById(
        'notification-container'
      )
      const notificationNodes = Array.from(notificationsContainer.children)
      markVisibleNotificationsAsRead()

      function markVisibleNotificationsAsRead() {
        notificationNodes.forEach((notification, idx, arr) => {
          const curHeight =
            (notificationsContainer.scrollHeight / arr.length) * (idx + 1)
          const isVisible =
            notificationsContainer.scrollTop <= curHeight &&
            notificationsContainer.scrollTop +
              notificationsContainer.clientHeight +
              1 >=
              curHeight

          const notificationId = Number(notification.id.split('-')[1])
          const isRead = notifications.value.find(
            n => n.id === notificationId
          ).isRead

          if (isVisible && !isRead) {
            store.dispatch('readNotification', notificationId)
          }
        })
      }

      notificationsContainer.addEventListener(
        'scroll',
        markVisibleNotificationsAsRead
      )

      onUnmounted(() => {
        notificationsContainer.removeEventListener(
          'scroll',
          markVisibleNotificationsAsRead
        )
      })
    })

    return {
      notifications,
      notificationTypesMap,
      curMessage,
    }
  },
}
</script>

<style lang="scss" scoped>
.notification {
  margin-bottom: 0.5rem;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  padding: 0.5rem 0.5rem 0.5rem 0;

  &:hover {
    background: #f0f0f0;
  }

  &__container {
    width: 380px;
    height: auto;
    max-height: calc(100vh - 10rem);
    position: relative;
    overflow: auto;
    flex-grow: 1;
    padding-right: 1rem;
  }

  &__status {
    width: 5px;
    height: 80px;
    border-radius: 0 25px 25px 0;
    background: #010e30;
    margin-right: 0.5rem;

    &.isRead {
      background: transparent !important;
    }
  }
}
</style>
