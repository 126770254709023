<template>
  <div>
    <div v-if="canSign">
      <v-btn class="w-100" @click="signDocument"> Підписати </v-btn>
      <v-divider class="mb-2"></v-divider>
    </div>
    <SignatureStatus :document="document" />
  </div>
</template>

<script lang="ts">
import SignatureStatus from '@/components/signature/SignatureStatus.vue'
import { openDialog } from '@/dialog'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'DocumentSign',
  components: { SignatureStatus },
  emits: ['set-signature'],
  props: {
    document: {
      type: Object,
    },
  },
  setup(props) {
    const store = useStore()
    const userId = computed(() => store.state.user.userData?.id)

    const canSign = computed(() => {
      return (
        props.document.statusId !== 4 &&
        props.document.users?.find(u => u.userId === userId.value)?.isSignature
      )
    })

    const signDocument = () => {
      openDialog({
        name: 'SignDocument',
        dialogData: {
          document: props.document.$object,
          entityType: 'user',
        },
        params: {
          hideActionButtons: true,
          cb: doc => {
            props.document.$set(doc.at(-1))
            // emit('set-signature', signatures)
          },
        },
      })
    }

    return { signDocument, canSign }
  },
}
</script>

<style scoped lang="scss"></style>
