<template>
  <div>
    <v-menu @update:model-value="$store.commit('confirmNotificationQueue')">
      <template #activator="{ props }">
        <v-btn
          style="background: transparent"
          size="small"
          stacked
          v-bind="props"
          variant="text">
          <v-badge
            :model-value="!!unreadNotificationsLength"
            :content="unreadNotificationsLength"
            color="error">
            <v-icon style="font-size: 27px; color: #202020"
              >{{ 'mdi-bell-outline' }}
            </v-icon>
          </v-badge>
        </v-btn>
      </template>

      <NotificationList></NotificationList>
    </v-menu>
  </div>
</template>

<script>
import NotificationList from './NotificationList.vue'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'Notifications',
  components: { NotificationList },
  setup() {
    const store = useStore()
    store.dispatch('getNotifications').then(() => {
      store.dispatch('listenNotificationEvents')
    })
    const notifications = computed(
      () => store.state.notifications.notificationList
    )
    const unreadNotificationsLength = computed(() => {
      return notifications.value.filter(n => !n.isRead).length
    })

    return { notifications, unreadNotificationsLength }
  },
}
</script>
