<template>
  <div>
    <BSectionLoader :loading="$loading.isLoading('getDiiaData')">
      <div>
        <div class="mb-3">
          Відскануйте QR код сканером у застосунку Дія або перейдіть за
          <a :href="diiaData.link" class="link" target="_blank">посиланням</a>
          та дотримуйтесь інструкцій.
        </div>

        <div class="d-flex justify-center mb-3">
          <v-img
            :src="diiaData.qrCode"
            cover
            aspect-ratio="1"
            inline
            width="256px"
            height="256px"></v-img>
        </div>

        <div class="mb-5 text-center">
          <span v-if="getDiiaDataTimeout > 0">
            Час життя QR коду: {{ diiaDataTimer }}
          </span>
          <div v-else>
            <b class="d-block text-warning mb-3">Час дії коду закінчився</b>
            <v-btn variant="outlined" color="success" @click="getDiiaData">
              Оновити код
            </v-btn>
          </div>
        </div>

        <v-btn color="secondary" variant="outlined" @click="dialog.close()">
          Скасувати
        </v-btn>
      </div>
    </BSectionLoader>
  </div>
</template>

<script lang="ts">
import { computed, PropType, reactive, ref } from 'vue'
import { Dialog } from '@/dialog'
import { handleAsync } from 'best-modules/plugins'
import axios from '@/plugins/axios/index.js'
import { urlGetDiiaData, urlClientAccessGetDiiaData } from '@/utils/urls.js'
import { BSectionLoader } from 'best-modules/components'
import { padStart } from 'lodash'
import { socket, documentSignatureSingleChannel } from '@/socket.js'
import { closeDialog } from '@/dialog'

export default {
  name: 'SignDiia',
  components: { BSectionLoader },
  props: {
    dialog: {
      type: Object as PropType<Dialog>,
    },
  },
  setup(props) {
    const getDefaultSendCodeTimeout = (): number => {
      const t = localStorage.getItem('getDiiaDataTimeout')
      if (t) {
        return Number(t)
      } else {
        return 0
      }
    }

    let interval: number
    const getDiiaDataTimeout = ref<number>(getDefaultSendCodeTimeout())
    const diiaDataTimer = computed<string>(() => {
      const minutes = Math.floor(getDiiaDataTimeout.value / 60)
      const seconds = getDiiaDataTimeout.value - minutes * 60

      return `${padStart(String(minutes), 2, '0')}:${padStart(
        String(seconds),
        2,
        '0'
      )}`
    })

    const setGetDiiaDataInterval = () => {
      clearInterval(interval)
      interval = setInterval(() => {
        if (getDiiaDataTimeout.value === 0) {
          clearInterval(interval)
          return
        }
        getDiiaDataTimeout.value -= 1
        localStorage.setItem(
          'shareDocumentCodeTimeout',
          String(getDiiaDataTimeout.value)
        )
      }, 1000)
    }

    const diiaData = reactive({
      qrCode: null,
      link: null,
    })

    const getDiiaData = () => {
      return handleAsync('getDiiaData', () => {
        const url = props.dialog.dialogData.isClient
          ? urlClientAccessGetDiiaData()
          : urlGetDiiaData(props.dialog.dialogData.documentId)
        return axios({
          url,
          method: 'get',
          headers: {
            Authorization: props.dialog.dialogData?.Authorization,
          },
        }).then(res => {
          getDiiaDataTimeout.value = 180
          setGetDiiaDataInterval()
          Object.assign(diiaData, res.data)
        })
      })
    }

    getDiiaData().then(() => {
      socket.on(documentSignatureSingleChannel, res => {
        if (props.dialog.dialogData.documentId === res.id) {
          closeDialog()
        }
      })
    })

    return { diiaData, getDiiaData, getDiiaDataTimeout, diiaDataTimer }
  },
}
</script>

<style scoped lang="scss"></style>
