<template>
  <v-app-bar class="py-2 px-3" density="compact">
    <v-row class="align-center">
      <v-col class="pb-0 pt-0" cols="2">
        <RouterLink style="text-decoration: none" to="/eds">
          <NewLogoBlack />
        </RouterLink>
      </v-col>

      <v-col v-if="$vuetify.display.mdAndUp" class="pb-0 pt-0" cols="4">
        <div style="margin-left: 4px">
          <b-autocomplete
            :key="searchRenderKey"
            :items="searchItems"
            :menu-props="{ width: '600px' }"
            hide-details
            item-title="title"
            item-value="value"
            no-filter
            placeholder="Пошук"
            style="min-width: 12rem"
            @update:search="getSearchItems"
            @update:model-value="searchRenderKey++">
            <template #prepend-inner>
              <v-icon class="mt-1" icon="mdi-magnify" />
            </template>
          </b-autocomplete>
        </div>
      </v-col>
      <v-col class="pb-0 pt-0" cols="9" md="6">
        <div class="d-flex align-center gap-2 justify-end">
          <div v-if="$vuetify.display.smAndUp">
            <div
              v-if="user"
              class="mr-2"
              style="font-size: 14px; font-weight: 600">
              Привіт
              <v-icon icon="best:emoji-hand" />
              ,
              {{ [user.secondName, user.name].filter(Boolean).join(' ') }}
            </div>
            <Skeleton v-else :rows="1" height="1.5rem" width="18rem" />
          </div>

          <v-menu>
            <template #activator="{ props }">
              <div class="d-flex gap-1 align-center">
                <BaseImage
                  v-if="user && user.photo"
                  :src="user.photo"
                  rounded
                  style="cursor: pointer"
                  v-bind="props"
                  width="40"></BaseImage>
                <v-avatar
                  v-else
                  class="cursor-pointer"
                  color="primary"
                  v-bind="props">
                  <v-icon icon="mdi-account-circle"></v-icon>
                </v-avatar>
                <NotificationTemplate />
              </div>
            </template>

            <v-list>
              <v-list-item
                v-for="(item, idx) in userActions"
                :key="idx"
                @click="handleUserAction(item.command)">
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import Skeleton from '@/components/Skeleton.vue'
import { useGlobalSearch } from './globalSearch.js'
import { useUserActions } from './userActions.js'
import NotificationTemplate from '@/components/notifications/Notifications.vue'
import NewLogoBlack from '@/components/icon/icons/black-new-logo.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'

export default {
  components: {
    NotificationTemplate,
    Skeleton,
    NewLogoBlack,
  },
  props: {
    viewMode: {
      type: String,
      default: 'full',
      validator: val => ['full', 'small'].includes(val),
    },
  },
  setup() {
    const store = useStore()
    const user = computed(() => store.state.user.userData || {})

    return {
      user,
      ...useGlobalSearch(),
      ...useUserActions(),
    }
  },
}
</script>

<style lang="scss" scoped></style>
