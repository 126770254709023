<template>
  <div>
    <Filter
      v-model="filters"
      v-model:loading="loading"
      @filter="() => pgTrigger++" />
    <v-data-table
      :headers="headers"
      :hide-default-footer="true"
      :hide-default-header="!observerList.length"
      :items="observerList"
      :items-per-page="-1"
      :loading="loading"
      dense
      @dblclick:row="openRow">
      <template #item.author="{ item }">
        {{ getUserName(item.author, { initials: true }) }}
      </template>
    </v-data-table>
    <Pagination :getItems="getObserverUsers" :trigger="pgTrigger" />
  </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue'
import { axios } from '@/plugins'
import { getUserName, navigateToItem } from '../../utils/helpers'
import { urlGetObserverUsers } from '@/utils/urls'
import Pagination from '@/components/Pagination.vue'
import Filter from '@/components/Filter.vue'

export default {
  name: 'ObserverList',
  components: { Filter, Pagination },
  methods: { getUserName },
  setup() {
    const loading = ref(false)
    const observerList = ref([])
    const headers = ref([
      {
        key: 'status.name',
        title: 'Статус задачі',
      },
      {
        key: 'deadline',
        title: 'Термін виконання',
      },
      {
        key: 'author',
        title: 'Нагладач',
      },
    ])

    const filters = reactive({
      search: null,
      observerStatusId: null,
      observerCompiledDate: null,
      observerDeadlineDate: null,
    })

    const openRow = (e, row) => {
      navigateToItem('single-task', row.item.id)
    }

    const getObserverUsers = () => {
      loading.value = true
      return axios.get(urlGetObserverUsers({ filters })).then(res => {
        observerList.value = res.data.data
        loading.value = false

        return res.data
      })
    }
    const pgTrigger = ref(1)

    return {
      headers,
      observerList,
      loading,
      getObserverUsers,
      pgTrigger,
      openRow,
      filters,
    }
  },
}
</script>

<style lang="scss" scoped></style>
